import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/seo"

const privacy = () => {
  return (
    <Layout>
      <Seo title="Termini e condizioni" />
      <div className="min-h-screen bg-gray-50 py-8 flex flex-col justify-center relative overflow-hidden lg:py-12">
        <div className="relative w-full px-6 py-12 prose prose-slate lg:prose-lg prose-sky bg-white shadow-xl shadow-slate-700/10 ring-1 ring-gray-900/5 md:max-w-3xl md:mx-auto lg:max-w-4xl lg:pt-16 lg:pb-28">
          <p className="my-6 text-xl md:text-3xl font-extrabold text-sky-900 uppercase tracking-wider">
          Termini e condizioni Click’n’ Pay Fidelity
          </p>
<p><strong>Termini e Condizioni del programma di fidelizzazione Click’n’Pay</strong></p>
<h3><strong>1. INFORMAZIONI SULL’ ATTIVITÀ e SUL PRESENTE ACCORDO</strong></h3>
<p>1.1 Questi ‘Termini e Condizioni’ si riferiscono al programma fedeltà fornito dal sito e-commerce <a href="http://www.clicknpay.it/">www.clicknpay.it</a> di proprietà della Tutor Point Srls. </p>
<p>Il programma Click’n’Pay Fidelity (di seguito C’n’P Fidelity) è un marchio di Tutor Point Srls. Nel presente documento, il termine “utente” è riferito a colui che si registra al sito Click’n’Pay e aderisce al programma fedeltà. </p>
<p>1.2 Gestione programma fedeltà. </p>
<p>Nel presente documento, il termine "società" è riferito al gestore del programma in questione. </p>
<p>1.3 Il programma C’n’P Fidelity ed i sistemi tecnologici necessari al funzionamento dello stesso, sono forniti da Tutor Point Srls. </p>
<p>1.4 Il servizio di assistenza al cliente per il C’n’P Fidelity è fornito da Tutor Point Srls come indicato nella sezione 12 di seguito riportata. </p>
<p>1.5 Il presente documento sancisce le regole che intercorrono tra utente e società, che disciplinano l’utilizzo del C’n’P Fidelity. L'accordo avrà una durata di 12 mesi, a partire dalla data in cui si è aderito e tacitamente rinnovato fino alla cancellazione del profilo utente. Il presente accordo e tutte le comunicazioni tra utente e società saranno disponibili in lingua italiana.  </p>
<h3><strong>2. PROGRAMMA Click’n’Pay Fidelity</strong></h3>
<p>2.1   Il programma C’n’P Fidelity offre agli iscritti al sito Click’n’Pay la possibilità di usufruire di un piano fedeltà di raccolta punti annuale, a fronte del quale l’utente ha diritto di riscattare dei buoni acquisto per beni e servizi all'interno di un circuito limitato di negozi e/o siti aderenti a siti e-commerce di fornitori terzi.</p>
<p>2.2 La durata del programma fedeltà per l’accumulo punti, legati ai servizi/prodotti sottoscritti tramite il sito, va dal 01/01 al 31/12 di ogni anno, ad eccezione della prima annualità che scadrà il 31/12/2023. L’utente, entro i 12 mesi solari potrà accumulare punti e poi riscattarli entro il 30/06 dell’anno successivo. Il riscatto dei buoni acquisto parte al raggiungimento dei 250 punti. </p>
<p>2.3 I punti, stabiliti per ogni servizio/prodotto sottoscritto/attivato tramite il sito <a href="http://www.clicknpay.it/">www.clicknpay.it</a>, vengono convertiti in buoni acquisto secondo la seguente modalità: 250 punti corrispondono ad un buono acquisto del valore di 25 €; 500 punti corrispondono ad un buono acquisto del valore di 50€; 1000 punti corrispondono ad un buono acquisto del valore di 100 €. Il dettaglio dei buoni, con corrispondente valore, viene riportato e meglio specificato nella sezione “dettaglio buoni” presente nell’area personale dell’utente.  </p>
<h3><strong>3. RICHIESTA D’IDENTIFICAZIONE UTENTE</strong></h3>
<p>3.1 Prima del riscatto dei punti del programma C’n’P Fidelity, verrà richiesto un controllo dell’identità dell’utente tramite procedura di verifica presente nell’area “modifica profilo” che chiede di allegare due selfie con in mano il documento ben visibile del fronte e del retro. Tale informazione è necessaria per la prevenzione da frodi e in caso di furto di identità. </p>
<p>La società conserverà queste informazioni solo finché sia necessario e per gli scopi descritti. </p>
<p>3.2 Al mancato reperimento della prova che verifichi l’identità del soggetto utente, la società si riserva il diritto di non erogare il servizio richiesto.  </p>
<p>Non verranno pertanto fornite spiegazioni sul rifiuto del sistema. </p>
<h3><strong>4. COSTI E SPESE</strong></h3>
<p>Per l’adesione al programma C’n’P Fidelity non è previsto alcun costo. </p>
<h3><strong>5. MODALITA’ D'USO PROGRAMMA C’n’P Fidelity</strong></h3>
<p>Il programma è ad esclusivo uso dell'utente registrato e non può essere dato in dono/utilizzato ad/da un'altra persona. L’adesione è concessa tramite registrazione al sito <a href="http://www.clicknpay.it. ">www.clicknpay.it. </a></p>
<h3><strong>6. RESTRIZIONI PER L'USO DEL PROGRAMMA C’n’P Fidelity</strong></h3>
<p>6.1 C’n’P Fidelity non è progettato per essere utilizzato per la rivendita per corrispondenza, su Internet, o per altre operazioni. </p>
<p>6.2 C’n’P Fidelity e i buoni acquisto connessi non sono collegati ad un conto bancario né sono una carta di garanzia, né una carta di debito o di credito, così come non possono essere utilizzati come prova d’identità. </p>
<p>6.3 I buoni acquisto non possono essere utilizzati per pagamenti regolari pre-autorizzati, per il gioco d'azzardo, o per scopi illegali. </p>
<h3><strong>7. SCADENZA PROGRAMMA</strong></h3>
<p>7.1 C’n’P Fidelity cesserà di essere valido alla scadenza dell’anno solare. Tale data è definita come "data di scadenza" ovvero il 31/12 (trentuno dicembre) di ogni anno. A quella data, C’n’P Fidelity sarà azzerato. Il nuovo piano di accumulo punti partirà dal 01/01 di ogni anno solare. </p>
<p>Resta intesa la validità del riscatto punti come da art. 2.2 del presente documento. </p>
<p>7.2 Per qualsiasi dubbio circa C’n’P Fidelity, si può contattare il numero 06/59877456. </p>
<h3>8. <strong>CONSERVARE I DATI DI ACCESSO AL C’n’P Fidelity AL SICURO</strong></h3>
<p>L’accesso al programma C’n’P Fidelity avviene mediante registrazione al sito <a href="http://www.clicknpay.it/">www.clicknpay.it</a>. Si ricorda che i dati di accesso dovranno essere conservati in un luogo sicuro. Ciò significa che è necessario prendere tutte le misure ragionevoli per evitare la loro perdita, il furto o l’utilizzo abusivo degli stessi. </p>
<p>La società non è responsabile di nessuna attività prodotta all’interno del sistema web clicknpay.it derivante dall’incuria dell’utente nella gestione di tali dati. </p>
<h3><strong>9. SMARRIMENTO E FURTO DELLE CREDENZIALI</strong></h3>
<p>9.1 In caso di smarrimento delle credenziali l’utente può procedere in maniera autonoma alla richiesta di ripristino dei dati di accesso mediante il sito clicknpay.it o, nel caso sia impossibilitato a farlo, a contattarci a mezzo mail all’indirizzo: <a href="mailto:help@clicknpay.it">help@clicknpay.it</a> </p>
<p>9.2 Nel caso l’utente abbia riscontrato un furto dei dati di accesso è tenuto a comunicare tempestivamente l’accaduto per porre in atto le dovute azioni di tutela. </p>
<p>Sarà chiesto di fornire il numero utente e altre informazioni per verificare che la titolarità sia corrispondente. In seguito al processo di verifica, la società provvederà a bloccare immediatamente qualsiasi utenza associata per impedire l'uso non autorizzato di questa. </p>
<p>L’utente potrà utilizzare nuovamente i sistemi clicknpay.it solo previa reiscrizione, fermo restando la verifica e il ripristino dei punti maturati. </p>
<h3><strong>10. ACQUISTI E DISPUTE DELLE TRANSAZIONI</strong></h3>
<p>10.1 La Tutor Point srls non è responsabile per la sicurezza, la legittimità, la qualità o qualsiasi altro aspetto dei beni e servizi usufruiti con l’utilizzo del programma C’n’P Fidelity e del sito clicknpay.it.  </p>
<p>10.2 La Tutor Point srls non si assume responsabilità del mancato tracciamento delle operazioni effettuate sui siti partner, sia per problematiche tecnologiche originate dall’utente (presenza ad blocker, errato uso dei link, errata sottoscrizione contratto, browser non aggiornato…) che per quelle prodotte dall’operatore (link non funzionante, interruzione improvvisa della partnership, script di tracciamento difettoso). In mancanza di tale tracciamento non potrà essere verificato né calcolato nessun punto.   </p>
<h3><strong>11. SERVIZIO ASSISTENZA CLIENTI</strong></h3>
<p>11.1 Tutte le richieste telefoniche relative al programma C’n’P Fidelity devono essere effettuate chiamando il numero 06/59877456 o inviando una mail all’indirizzo: <a href="mailto:help@clicknpay.it">help@clicknpay.it</a>. </p>
<p>11.2 Il team del Servizio Assistenza al Cliente è a disposizione dalle 10:30 alle 12:30 e dalle 15:30 alle 17:30 dal lunedì al venerdì. In questo orario si cercherà di fare il possibile per risolvere immediatamente tutte le richieste; tuttavia, alcune richieste potranno essere soddisfatte solo durante il regolare orario di lavoro, dal lunedì al venerdì, dalle 09:30 alle 17:30. La corrispondenza ricevuta dopo la chiusura delle attività di un determinato giorno verrà considerata come se fosse arrivata all'inizio del giorno lavorativo successivo. </p>
<h3><strong>12. LIMITAZIONE DI RESPONSABILITA '</strong></h3>
<p>12.1 La società non sarà responsabile per qualsiasi: </p>
<ul>
<li>Perdita che non era prevedibile al momento dell’adesione al servizio;</li>
<li>Perdita che non sia stata causata da una violazione delle parti (ciascuna agendo individualmente);</li>
<li>Perdite aziendali e/o perdite di chi non sia un consumatore diretto.</li>
</ul>
<p>12.2 Se è stata usata o è stato permesso che l’utenza sia usata in modo fraudolento e/o in un modo non conforme ai presenti ‘Termini e Condizioni’, e/o per scopi illegali e/o per negligenza, l’utente ne sarà ritenuto responsabile.  </p>
<p>12.3 Avendo preso tutte le ragionevoli precauzioni necessarie, ed in considerazione delle condizioni di cui ai punti 10 e 12.2, la responsabilità per l’uso improprio dei dettagli e dello smarrimento o furto è esclusivamente dell’utente iscritto e la società si riserva il diritto di procedere legalmente a tutela della sua immagine e del suo lavoro. </p>
<h3><strong>13. DATI PERSONALI</strong></h3>
<p>13.1 La società raccoglie determinate informazioni sull’utente allo scopo di gestire il programma punti. Per raccogliere le informazioni personali Tutor Point agisce come Responsabile del Controllo dei Dati, così come descritto nella legge di Protezione dei Dati. </p>
<p>13.2 I dati personali saranno trattati da tutte le organizzazioni descritte ai punti 1.2, 1.3 e 1.4 del presente accordo allo scopo di gestire il programma C’n’P Fidelity e per far fronte ad eventuali richieste di informazioni sul programma stesso. Per fornire un servizio di assistenza al cliente la società potrà avvalersi dell'assistenza di processori di dati nel pieno rispetto della privacy. </p>
<p>13.3 I dati personali dell’utente non saranno utilizzati per scopi di marketing, né saranno condivisi con terze parti estranee al programma punti, tranne se ne è stato dato esplicito consenso.  </p>
<p>13.4 Aderendo al programma C’n’P Fidelity, l'utente accetta tutte le condizioni del presente documento di accordo per quanto riguarda l’uso dei dati personali. L’utente avrà il diritto di richiedere i dettagli delle informazioni personali dalla società conservate e potranno essere richieste scrivendo a <a href="mailto:help@clicknpay.it">help@clicknpay.it</a> </p>
<p><strong>14. MODIFICHE AL PRESENTE DOCUMENTO</strong></p>
<p>14.1 Il presente documento ‘Termini e Condizioni’ può essere cambiato o aggiornato in qualsiasi momento per motivi legali, di regolamento, di sicurezza, per consentire o migliorare il funzionamento del programma. Qualora vengano apportate modifiche, queste saranno rese pubbliche due mesi prima che le modifiche abbiano effetto (a meno che la legge imponga di effettuare una modifica più rapidamente); le copie aggiornate dei ‘Termini e Condizioni’ saranno rese disponibili online su <a href="http://www.clicknpay.it/">www.clicknpay.it</a> </p>
<p>14.2 È quindi responsabilità dell'acquirente, di controllare regolarmente il sito Internet <a href="http://www.clicknpay.it/">www.clicknpay.it</a> per le modifiche ai ‘Termini e Condizioni’. </p>
<h3><strong>15. LEGISLAZIONE E TRIBUNALI</strong></h3>
<p>15.1 Ai presenti ‘Termini e Condizioni’ si applica la legge italiana e sarà di esclusiva competenza del Tribunale di Roma a occuparsi di qualsiasi disputa. </p>
        </div>
      </div>
    </Layout>
  )
}

export default privacy
